const Limitations = {
    //
    // Adds a fan if the power consumption threshold is
    // reached the first time.
    //
    addChassisFanIfPowerConsumptionThresholdReached: 'addChassisFanIfPowerConsumptionThresholdReached',

    //
    // Allow additional daughter boards which would normally be rejected. For instance L Addons on R Boards.
    //
    additionalPermitAddOn: 'additionalPermitAddOn',

    //
    // Show a information when a product has the status "eol" (end of life)
    //
    endOfLife: 'endOfLife',

    //
    // Limits a item to a single product.
    //
    limitTo: 'limitTo',

    //
    // Limits a specific list of add ons to a mainboard.
    //
    limitToAddOn: 'limitToAddOn',

    //
    // Limits a slot to a specific group of cards.
    //
    limitToCardsInSlot: 'limitToCardsInSlot',

    //
    // Limits an addon to a specific amount in the chassis.
    //
    limitToAmountInChassis: 'limitToAmountInChassis',

    //
    // Limits a slot to a specific group of power supply units.
    //
    limitToPsusInSlot: 'limitToPsusInSlot',

    //
    // Limits sfp modules to cards
    //
    limitToAmount: 'limitToAmount',

    //
    // Limits the amount of a group of cards in a chassis. So if the limit
    // is set to 1, only one card in the group is able to get configured in the chassis.
    //
    limitToAmountOfGroupInChassis: 'limitToAmountOfGroupInChassis',

    //
    // Adds an error when a module is added to an invalid slot.
    //
    limitToSlotInChassis: 'limitToSlotInChassis',

    //
    // Shows an information if requiresFanInChassis is not set
    // and no fan is configured. Also it shows an information if the
    // module fan is not on the recommended position.
    //
    recommendsModuleFanInChassis: 'recommendsModuleFanInChassis',

    //
    // Adds an error when an (extender) module requires a fan but no fan is
    // configured for the given chassis.
    //
    requiresFanInChassis: 'requiresFanInChassis',

    //
    // Adds a requirement that the card has to be placed
    // over a host module.
    //
    requiresHostModule: 'requiresHostModule',

    //
    // Displays an information when the current power consumption is greater
    // than the recommended power consumption.
    //
    showPowerConsumptionThresholdWarning: 'showPowerConsumptionThresholdWarning',

    //
    // Displays an error when the power consumption is highter than the
    // available power consumption of the chassis.
    //
    showPowerConsumptionOverloadWarning: 'showPowerConsumptionOverloadWarning',

    //
    // Shows a special power consumption warning when a card wit usb ports is connected
    //
    showUsbWarning: 'showUsbWarning',
};

module.exports = Limitations;
