//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const english = {
    accessories:                                           'Accessories',
    actions:                                               'Actions',
    add:                                                   'Add',
    addOnModules:                                          'Add-on module',
    addSingleOrderSlot:                                    'add another slot',
    amount:                                                'Amount',
    amountInputPlaceholder:                                '0',
    and:                                                   'and',
    appName:                                               '{{applicationName}}',
    assemblyOrder123:                                      '1•2•3',
    assemblyOrder135:                                      '1•3•5',
    assemblyOrder:                                         'Assembly order',
    audio:                                                 'Audio',
    audioAnalogBidirectional:                              'Analog bidirectional',
    audioAnalogDigital:                                    'Analog digital',
    audioBalanced:                                         'symmetric',
    audioDigitalBidirectional:                             'Digital bidirectional',
    audioDigitalUnidirectional:                            'Digital unidirectional',
    back:                                                  'Back',
    canBeOrderedEmpty:                                     'Can be ordered empty',
    cancel:                                                'Cancel',
    cardOverlay:                                           'Card overlay',
    cardOverlayModeArticleNumber:                          'Part number',
    cardOverlayModeModuleName:                             'Module name',
    cardOverlayModeNone:                                   'Nothing',
    cardOverlayRenderExtenderId:                           'Also show extender id on card overlay',
    cardOverlayTooltipText:                                'This setting defines whether nothing, the part number or your individual module name is displayed on a card.',
    centimeter:                                            'Centimeter',
    centimeterShort:                                       'cm',
    chassis:                                               'Chassis',
    chassisAccessories:                                    'Chassis accessories',
    chassisSize:                                           'Width x height x depth',
    chassisSizeShort:                                      'W x H x D',
    chooseFanType:                                         'Choose fan type',
    chooseFanTypeText:                                     'Due to thermal conditions, a chassis or module fan is absolutely necessary. Please choose a fan type you want to add automatically.',
    chosenFile:                                            'Chosen file',
    classic:                                               'Classic',
    closeOverlay:                                          'Close',
    closeProject:                                          'Close',
    closeProjectButton:                                    'Close and continue',
    closeProjectText:                                      'You are about to leave the active project. Would you like to continue? Your project will be saved and you can reopen it at any time via the project list.',
    closeProjectTitle:                                     'Close project?',
    comment:                                               'Comment',
    company:                                               'Company',
    conPorts:                                              'CON ports',
    conUnit:                                               'CON (Remote)',
    configurable:                                          'configurable',
    configurableNo:                                        'No',
    configurableYes:                                       'Yes',
    configurationGroup:                                    'Configuration {{groupIndex}}',
    configurationIsSavedInBrowserCache:                    'Your configuration is currently saved in the browser cache.',
    confirmOverwriteSlotOkButton:                          'Overwrite slot',
    confirmOverwriteSlotOkTextMoveSlot:                    'Are you sure you want to replace the card {{toSlot}} on position {{toSlotIndex}} by moving with the card {{fromSlot}} from position {{fromSlotIndex}}?',
    confirmOverwriteSlotOkTextSetSlot:                     'Are you sure you want to replace the card {{toSlot}} on position {{toSlotIndex}} with the card {{newProductNumber}}?',
    confirmOverwriteSlotOkTitle:                           'Overwrite slot?',
    contact:                                               'Contact',
    contactOpeningHours:                                   'Opening hours',
    contactOpeningHoursText1:                              'Monday to Thursday from 8.00 to 16.30 o\'clock',
    contactOpeningHoursText2:                              'Friday from 8.00 to 15.00 o\'clock',
    contactOpeningHoursText3:                              'By fax/e-mail around the clock, of course.',
    contactOrderProcessingText:                            'Questions about the current order/delivery date',
    contactOrderProcessingTitle:                           'Order Processing',
    contactPageTitle:                                      'Contact',
    contactPhoneRequest:                                   'Need to reach us by phone?',
    contactPhoneHintText:                                  'All other locations, click <0>here</0> to view our global offices.',
    contactSalesConsultingText:                            'Consulting, project planning & general technical questions',
    contactSalesConsultingTitle:                           'Sales & Consulting',
    contactTechnicalSupportText:                           'After Sales & Support',
    contactTechnicalSupportTitle:                          'Technical Support',
    contactTitle:                                          'Consulting, project planning & general technical questions',
    contactMailTitle:                                      'For all consulting, project planning & general technical questions related to this configurator tool, please email our dedicated DKM Support Team at <0>{{salesEmail}}</0>',
    cpuBoards:                                             'CPU boards',
    cpuPorts:                                              'CPU ports',
    cpuUnit:                                               'CPU (Local)',
    customName:                                            'Custom name',
    customNameTip:                                         'For a better overview, you can assign an individual name to each chassis and module.',
    dataProtection:                                        'Data protection',
    dataProtectionAbbreviation:                            'GDPR',
    dataProtectionPageTitle:                               'Data protection',
    dataProtectionShort:                                   'Data protection',
    dataTransfer:                                          'Link interfaces',
    dataTransferCatx1g:                                    'Cat X 1G',
    dataTransferCatx3g:                                    'Cat X 3G',
    dataTransferFiber10g:                                  'Fiber 10G',
    dataTransferFiber1g:                                   'Fiber 1G',
    dataTransferFiber3g:                                   'Fiber 3G',
    dataTransferFiber6g:                                   'Fiber 6G',
    dataTransferIpcatx1g:                                  'Cat X 1G IP',
    dataTransferIpfiber10gmm:                              'Fiber 10G (MM)',
    dataTransferIpfiber1g:                                 'Fiber 1G IP',
    dataTransferSingular:                                  'Link interface',
    dataTransferUni:                                       'Uni',
    dataTransferWithDash:                                  'Link interfaces',
    debug:                                                 'Debug',
    deleteChassis:                                         'Delete chassis',
    deleteExtenderButtonText:                              'Delete chassis',
    deleteExtenderText:                                    'Are you sure you want to remove the chassis {{name}} from this project? All configured cards will also be deleted.',
    deleteExtenderTitle:                                   'Delete chassis?',
    deleteFullIpButtonText:                                'Delete device',
    deleteFullIpText:                                      'Are you sure you want to remove the device {{name}} from this project?',
    deleteFullIpTitle:                                     'Delete device?',
    deleteMatrixButtonText:                                'Delete matrix switch',
    deleteMatrixText:                                      'Are you sure you want to remove the matrix switch chassis {{name}} from this project? All configured cards will also be deleted.',
    deleteMatrixTitle:                                     'Delete matrix switch?',
    deleteModules:                                         'Delete modules',
    deleteModulesOverlayAll:                               'All Modules',
    deleteModulesOverlayEmptyText:                         'The currently opened chassis contains no modules that could be deleted.',
    deleteModulesOverlayOkButtonText:                      'Delete modules',
    deleteModulesOverlayText:                              'Please select the article numbers of the modules you want to delete.',
    deleteModulesOverlayTitle:                             'Delete modules',
    deleteModulesTip:                                      'With this action you can select in an overlay which modules you want to delete from this chassis. A click on this button itself does not delete any modules. It only shows the overlay.',
    deleteOptionButtonText:                                'Remove option',
    deleteOptionTitle:                                     'Remove option?',
    deleteProject:                                         'Delete project',
    deleteProjectButtonText:                               'Delete project',
    deleteProjectText:                                     'Are you sure you want to remove the project "{{title}}"? The project will be deleted permanently.',
    deleteProjectTitle:                                    'Delete project?',
    deleteSfpButtonText:                                   'Delete SFP module',
    deleteSfpExtenderText:                                 'Are you sure you want to remove the SFP module {{sfpProductNumber}} at position {{index}} from this card ({{slotName}})?',
    deleteSfpMatrixText:                                   'Are you sure you want to remove the SFP module {{sfpProductNumber}} at position {{index}} from this card ({{slotName}})?',
    deleteSfpTitle:                                        'Delete SFP module?',
    deleteSingleOrderSlotButtonText:                       'Delete',
    deleteSingleOrderSlotEquipmentText:                    'Are you sure you want to delete the equipment single order {{name}} from item {{index}}?',
    deleteSingleOrderSlotExtenderText:                     'Are you sure you want to delete the extender single order {{name}} from item {{index}}?',
    deleteSingleOrderSlotFullIpText:                       'Are you sure you want to delete the device single order {{name}} from item {{index}}?',
    deleteSingleOrderSlotMatrixText:                       'Are you sure you want to delete the matrix switch single order {{name}} from item {{index}}?',
    deleteSingleOrderSlotTitle:                            'Delete single order?',
    deleteSlotButtonText:                                  'Clear slot',
    deleteSlotExtenderAccessoryText:                       'Are you sure you want to remove the chassis accessories with the product {{name}} from this extender chassis?',
    deleteSlotExtenderFanText:                             'Are you sure you want to remove the fan with the product {{name}} from this extender chassis?',
    deleteSlotExtenderPowerSupplyUnitText:                 'Are you sure you want to remove the power supply with the product {{name}} from this extender chassis?',
    deleteSlotExtenderSlotText:                            'Are you sure you want to empty the slot {{index}} with the product {{name}} from this extender chassis?',
    deleteSlotFullIpSlotText:                              'Are you sure you want to remove the option {{index}} with the name {{name}} from this device?',
    deleteSlotMatrixAccessoryText:                         'Are you sure you want to remove the chassis accessories with the product {{name}} from this matrix chassis?',
    deleteSlotMatrixFanText:                               'Are you sure you want to remove the fan with the product {{name}} from this matrix chassis?',
    deleteSlotMatrixFanTextRequiredInChassis2:             'When you continue, a chassis fan is automatically added.',
    deleteSlotMatrixFanTextRequiredInChassis:              'When you continue, a fan cartridge module is added automatically.',
    deleteSlotMatrixFanTextRequiredInChassisOverwriteSlot: 'The slot {{slotName}}} is overwritten with the fan cartridge module.',
    deleteSlotMatrixFanTextRequiredInChassisWithSlot:      'If you continue, a fan cartridge module will automatically be added to {{slot}}.',
    deleteSlotMatrixPowerSupplyUnitText:                   'Are you sure you want to remove the power supply with the product {{name}} from this matrix chassis?',
    deleteSlotMatrixSlotText:                              'Are you sure you want to empty the slot {{index}} with the product {{name}} from this matrix chassis?',
    deleteSlotTitle:                                       'Clear slot?',
    depth:                                                 'Depth',
    description:                                           'Description',
    descriptionKey:                                        'description_enShort',
    descriptionKeyFull:                                    'description_enFull',
    descriptionKeyFullShort:                               'enFull',
    descriptionKeyShort:                                   'enShort',
    deviceType:                                            'Device type',
    downloadImage:                                         'Download image',
    downloadImageBackend:                                  'Open backend rendering',
    downloadImageBackendTip:                               'Opens the current product in a view that was generated using the server sided rendering. This feature is a debug feature and not visible in the production system',
    downloadImageTip:                                      'This function allows you to download a picture of the device you have configured. This can be useful, for example, for further use in your documentation or other planning tools.',
    downloadInProgress:                                    'Download in progress...',
    downloadSingleOrderImageTip:                           'This function allows you to download a picture of the selected product.',
    downloads:                                             'Downloads',
    downloadsNoFilesAvailable:                             'Unfortunately no downloads are currently available for the selected product.',
    downloadsSelectFile:                                   'Here you see all downloads for the product {{partNumber}}:',
    dropZoneTextDelete:                                    'Drop here to delete.',
    dropZoneTextEquipment:                                 'Drag a device into this area to add it to your project.',
    dropZoneTextExtender:                                  'Drag an extender chassis into this area to add it to your project.',
    dropZoneTextMatrix:                                    'Drag a matrix chassis into this area to add it to your project.',
    duplicateExtender:                                     'Duplicate',
    duplicateExtenderTip:                                  'With this function you can create a copy of the currently opened device and edit and configure it separately from the original.',
    duplicateProductOverlayAmount:                         'Duplicate amount',
    duplicateProductOverlayText:                           'Enter the amount of duplications you want to add of {{name}}.',
    duplicateProductOverlayTitle:                          'Duplicate',
    duplicatedExtenderText:                                'The chassis ("{{name}}") was successfully duplicated. Would you like to go to the copy?',
    duplicatedExtenderTitle:                               'Chassis duplicated',
    duplicatedFullIpText:                                  'The device ("{{name}}") was successfully duplicated. Would you like to go to the copy?',
    duplicatedFullIpTitle:                                 'Device duplicated',
    duplicatedMatrixSwitchText:                            'The matrix switch ("{{name}}") was successfully duplicated. Would you like to go to the copy?',
    duplicatedMatrixSwitchTitle:                           'Matrix switch duplicated',
    duplicatedMultiplyExtenderText:                        'The chassis ("{{name}}") was successfully duplicated. Would you like to go to the last copy?',
    duplicatedMultiplyFullIpText:                          'The device ("{{name}}") was successfully duplicated. Would you like to go to the copy?',
    duplicatedMultiplyMatrixSwitchText:                    'The matrix switch ("{{name}}") was successfully duplicated. Would you like to go to the copy?',
    eMailAddress:                                          'E-Mail address',
    easyline:                                              'EasyLine (No matrix management)',
    efficiency:                                            'efficiency',
    empty:                                                 'Empty',
    emptyPlaceholderShort:                                 '-',
    enableSingleOrdersText:                                'Single orders',
    enableSingleOrdersTooltipText:                         'When you enable single orders, the entry "Equipment" on the left hand side will be changed to "Equipment and single orders". This is where you can also order all our products individually. Added single orders are also displayed if you have deactivated this setting with your project still containing single orders.',
    endOfLifeProductOverlayText:                           'The selected module is now at the end of life and cannot be selected anymore.',
    endOfLifeProductOverlayTitle:                          'Module not available',
    enterOfferNumberPlaceholder:                           'Enter quotation number...',
    enterOptionNumber:                                     'Enter option number...',
    enterProductNumber:                                    'Enter part number...',
    enterTextPlaceholder:                                  'Enter text...',
    equipment:                                             'Equipment',
    equipmentAndSingleOrders:                              'Equipment and single orders',
    error:                                                 'Error',
    errorsInYourConfiguration:                             'Errors in your configuration',
    export:                                                'Export',
    exportAbasTitle:                                       'ABAS',
    exportAbasTitleSubTitle:                               'Export for the ERP system from {{companyName}}',
    exportDialogText:                                      'Please select the desired file format',
    exportDownloadSuccessText:                             'The project file was successfully downloaded and is now in the location you selected.',
    exportDownloadSuccessTitle:                            'Download completed',
    exportDracoSystemDesignerSubTitle:                     'Export for immediate continued processing in {{applicationName}}',
    exportDracoSystemDesignerTitle:                        '{{applicationName}} ({{exportFilename}})',
    exportDracoTeraToolTitle:                              'Draco Tera Tool',
    exportDracoTeraToolTitleSubTitle:                      'Export for continued processing in Draco Tera Tool',
    exportError1:                                          'An active internet connection is required to export the project.',
    exportIhseSalesMailboxSubTitle:                        'We would be pleased to prepare an offer for you, subject to change.',
    exportIhseSalesMailboxSubmit:                          'OK',
    exportIhseSalesMailboxSubmitError1:                    'Something went wrong while sending your data. Please try again later or contact our sales team directly.',
    exportIhseSalesMailboxSubmitLoading:                   'Submitting...',
    exportIhseSalesMailboxSuccessText:                     'Thank you. We received your request. Our sales team will contact you as soon as possible with a non-binding offer!',
    exportIhseSalesMailboxSuccessTitle:                    'Message received',
    exportIhseSalesMailboxTitle:                           '{{companyName}} sales mailbox',
    extender:                                              'Extender',
    extenderId:                                            'Extender ID',
    extenderIdTipMainboard:                                'Here you can enter the extender id of this mainboard.',
    extenderIdTipUSB20:                                    'Here you can enter the extender id of this usb 2.0 module.',
    extenderPorts:                                         'Extender ports',
    extenderTabCompact:                                    'Assemblies / Compact',
    extenderTabMisc:                                       'Misc',
    extenderTabSecure:                                     'Secure',
    extenderTabVario:                                      '{{varioAlias}}',
    externalPsu:                                           'External power supply unit',
    externalPsuShort:                                      'Extern',
    fanAddedAutomaticallyHint:                             'Due to thermal conditions, a case fan was automatically added.',
    fanIndicatorHelp:                                      'This chassis has a fan configured to it.',
    fanPower:                                              'Fan power',
    fanType:                                               'Fan type',
    fanTypeChassis:                                        'Chassis fan',
    fanTypeModule:                                         'Module fan',
    fileDropZoneText:                                      'Drag a file into this window or click here to open a file selection.',
    fileNameDateFormat:                                    'DD-MM-YYYY-HH-mm-ss',
    filterActivePlural:                                    'Filter',
    filterActiveSingular:                                  'Filter',
    firstName:                                             'First name',
    flexline:                                              'FlexLine',
    frontPlates:                                           'Front plates',
    fullIpAndSwitching:                                    'Full Ip Extension & Switching',
    groupSimilarProductsText:                              'Group similar products',
    groupSimilarProductsTooltipText:                       'If you activate this setting, similar products will be grouped.',
    hasInternalPsu:                                        'With internal power supply',
    heatDissipation:                                       'Heat dissipation',
    heatDissipationChassisTip:                             'Heat dissipation of the chassis',
    heatDissipationExternal:                               'external',
    heatDissipationExternalPsuTip:                         'The heat dissipation of the external power supply unit',
    height:                                                'Height',
    heightUnits:                                           'Height units',
    help:                                                  'Help',
    helpPageTitle:                                         'Help',
    helpStepByStep1Text:                                   'Create new project.',
    helpStepByStep1Title:                                  'Step 1',
    helpStepByStep2Text:                                   'Enter project data.',
    helpStepByStep2Title:                                  'Step 2',
    helpStepByStep3Text:                                   'Configure extender.',
    helpStepByStep3Title:                                  'Step 3',
    helpStepByStep4Text:                                   'Configure matrix switch.',
    helpStepByStep4Title:                                  'Step 4',
    helpStepByStep5Text:                                   'Send request for quotation to {{companyName}} sales team and/or save your configuration locally.',
    helpStepByStep5Title:                                  'Step 5',
    helpStepByStep6Text:                                   'Print.',
    helpStepByStep6Title:                                  'Step 6',
    helpStepByStepTitle:                                   'Step by step',
    hid:                                                   'HID',
    hitLimitationOverlayText:                              'The selected module can not be added to this chassis.',
    hitLimitationOverlayTitle:                             'Hit limitation',
    homePage:                                              'Start page',
    homePageTitle:                                         'Start page',
    ihseCompanyName:                                       '{{companyName}} GmbH',
    ihseProjectNumber:                                     '{{companyName}} project number',
    ihseProjectNumberTip:                                  'Enter your {{companyName}} project number here, if you already know it.',
    ihseSalesMailbox:                                      '{{companyName}} sales mailbox',
    immutableIOBoard:                                      'I/O board',
    import:                                                'Import',
    importActiveProject:                                   'Import into an active project',
    importActiveProjectDialogText1:                        'Do you want to import the project into the active project?',
    importActiveProjectDialogText2:                        'You can also create a new project or cancel the process to close the active project first.',
    importDialogText1:                                     'Please select a file or drag a file into the box below.',
    importDialogText2:                                     'Supported file formats: {{fileFormats}}',
    importDialogTextContextDesigner:                       'All new products and configurations will be added to the project which is currently active.',
    importDialogTextContextNewProject:                     'All products and configurations are imported into a new project.',
    importError1:                                          'Please select the file you wish to import.',
    importError2:                                          'The file you selected could not be processed. Please check the file and try again.',
    importIntoNewProject:                                  'Import into new project',
    importSuccessActiveProjectText:                        'The import was completed successfully. All products and individual orders in the file have been imported into the project which is currently open.',
    importSuccessActiveProjectTitle:                       'Import completed',
    importSuccessNewProjectText:                           'The import was successfully completed and created as a new project. Click on "Open project" in the left corner of this dialog to open the project directly.',
    importSuccessNewProjectTitle:                          'Import completed',
    imprint:                                               'Imprint',
    imprintPageTitle:                                      'Imprint',
    incompatibleProductOverlayText:                        'The selected module is not supported in this chassis.',
    incompatibleProductOverlayTitle:                       'Incompatible module',
    information:                                           'Information',
    interlacedUpgrade:                                     'Interlaced upgrade',
    ioBoards:                                              'IO boards',
    itemNotFound:                                          'Entry not found',
    language:                                              'Language',
    languageEnglish:                                       'English', // Beware: All items starting with "language" should be in the language of the target (so "languageGerman" is "Deutsch" and not "German")
    languageGerman:                                        'Deutsch', // Beware: All items starting with "language" should be in the language of the target (so "languageGerman" is "Deutsch" and not "German")
    lastName:                                              'Surname',
    lastUpdate:                                            'Last change',
    lastUpdateFallback:                                    'Unknown',
    lastUpdateFormat:                                      'DD.MM.YYYY HH:mm:ss',
    limitationEndOfLife:                                   'This product reached its end of life and will no longer manufactured.',
    limitationLimitTo:                                     'This card cannot be used in this chassis.',
    limitationLimitToAmount:                               'This option can only be selected 1 time.',
    limitationLimitToAmountOfGroupInChassis:               'Together with the cards {{productNumbers}} in this chassis, this card can be configured {{limit}} times maximum.',
    limitationLimitToCardsInSlot:                          'Only one card of the type {{productList}} can be inserted in this slot.',
    limitationLimitToCardsInSlotReverse:                   'The card of type {{partNo}} may only be inserted in the following slots in this chassis: {{allowedSlotIndexes}}',
    limitationLimitToPsusInSlot:                           'This slot can only contain a power supply of the type {{productList}}.',
    limitationLimitToSlotInChassis:                        'This card can only be inserted in the following slots in this chassis: {{allowedSlotIndexes}}.',
    limitationRecommendsModuleFanInChassis1:               'Due to thermal conditions a module fan on slot {{slot}} is recommended.',
    limitationRecommendsModuleFanInChassis2:               'The module fan must be set to slot {{slot}} in the current configuration.',
    limitationRecommendsModuleFanInChassis3:               'At least one module fan must be set to slot {{slot}} in the current configuration.',
    limitationRequiresFanInChassis:                        'Due to thermal conditions, a chassis or module fan is mandatory.',
    limitationRequiresHostModule1:                         'An add-on module cannot be plugged into the first slot of a chassis.',
    limitationRequiresHostModule2:                         'An add-on module must be plugged onto a motherboard.',
    limitationRequiresHostModule3:                         'The underlying mainboard does not support add-on modules.',
    limitationRequiresHostModule4:                         'The type of this add-on module is not compatible with the type of the underlying mainboard (Local/Remote).',
    limitationShowPowerConsumptionOverloadWarning:         'The configured modules require more power ({{powerConsumptionInMilliamps}} mA) than the chassis can provide ({{maximumPowerConsumptionInMilliamps}} mA).',
    limitationShowPowerConsumptionThresholdWarning:        'The recommended heat dissipation has been exceeded. Please ensure adequate ventilation. Minimum distance between chassis: 0.5 HU.',
    limitationShowUsbWarning:                              'Using a USB extender module in this configuration allows maximum power consumption of {{availablePowerConsumptionInMilliamps}} mA by connected USB peripherals. By definition, no more than {{usbPowerConsumptionInMilliamps}} mA is allowed per USB port.',
    linkResolution:                                        'Link resolution',
    login:                                                 'Login',
    mainFullIp4KTab:                                       '4K',
    mainFullIpFullHdTab:                                   'HD',
    mainboardType:                                         'Technology',
    mainboards:                                            'Mainboards',
    matrixEndpoints:                                       'Matrix endpoints',
    matrixManagement:                                      'Matrix management',
    matrixPorts:                                           'Matrix ports',
    matrixSwitch:                                          'Matrix switch',
    matrixTabCatx1g:                                       'catx1g',
    matrixTabCompact:                                      'Compact',
    matrixTabEnterprise:                                   '{{enterpriseAlias}}',
    matrixTabFiber1g:                                      'fiber1g',
    matrixTabFiber3g:                                      'fiber3g',
    matrixTabFlex:                                         '{{flexAlias}}',
    matrixTabFlexChassis:                                  'Assemblies',
    matrixTabFlexChassisCustomDesign:                      'Custom design',
    matrixTabFlexIOBoards:                                 'I/O boards',
    matrixTabHybrid:                                       'hybrid',
    matrixTabUni:                                          'uni',
    maximumPowerConsumption:                               'Maximum power consumption',
    maximumPowerConsumptionShort:                          'Max. power consumption',
    maximumPowerConsumptionTip:                            'Current power consumption/maximum power consumption',
    media4Connect:                                         'Media4Connect',
    milliamps:                                             'Milliampere',
    milliampsShort:                                        'mA',
    millimeter:                                            'Millimeter',
    millimeterShort:                                       'mm',
    milliwatts:                                            'Milliwatt',
    milliwattsShort:                                       'mW',
    miscPorts:                                             'other ports',
    modulePlural:                                          'Modules',
    moduleSingular:                                        'Module',
    more:                                                  'More',
    nameOfResponsiblePerson:                               'Person responsible',
    nameOfResponsiblePersonFallbackName:                   'Not specified',
    nameOfResponsiblePersonTip:                            'Enter the name of the person responsible for the project.',
    newProject:                                            'New project',
    newVersionHintLaterButton:                             'Later',
    newVersionHintText:                                    'A new version of the {{applicationName}} is available, please update now.',
    newVersionHintUpdateButton:                            'Update',
    no:                                                    'No',
    noImageText:                                           'No image available',
    noInternetConnectionOverlayText:                       'Unfortunately an active internet connection is required for this function.',
    noInternetConnectionOverlayTitle:                      'Internet connection required',
    noProjectNameGiven:                                    'No project name given',
    noProjectsText:                                        'Choose between the following options: Create new project or import.',
    noProjectsTitle:                                       'No projects available',
    numberFilterPlaceholder:                               'e.g.: 3, 10-12, >20',
    numberFilterTip:                                       'Enter the number you want to search for here. In addition, you can define a range with a hyphen (-) (example: "4000-8000") or exclude values with the smaller (<) and larger symbol (>) (example: ">4000").',
    numberOfMonitors:                                      'Number of monitors',
    numberOfMonitorsTitle1:                                '1 monitor / Single-Head',
    numberOfMonitorsTitle2:                                '2 monitors / Dual-Head',
    offerNumber:                                           'Quotation number',
    offerNumberTip:                                        'The {{companyName}} quotation number can be stored in this field.',
    ok:                                                    'Ok',
    openProject:                                           'Open project',
    option:                                                'Option',
    optionalFanCount:                                      'Optional fans',
    optionalPsuCount:                                      'Optional power supply units',
    options:                                               'Options',
    optionsTip:                                            'Number of options available for this device',
    or:                                                    'or',
    otherComment:                                          'Other remarks',
    otherCommentTip:                                       'In this field, you can communicate additional comments, such as internal part or product numbers.',
    otherModules:                                          'Other modules',
    overlayDontShowAgain:                                  'Do not display this dialog again',
    overlayDontShowAgainSwitchToDuplicatedProduct:         'Do not display this dialog again and skip always to duplicated product',
    pageNotFound:                                          'Page not found',
    pageNotFoundPageTitle:                                 'Page not found',
    pageNotFoundText:                                      'The requested page could not be found. Please click on the logo at the top of the page to return to the home page. If you encounter this problem frequently, please contact our support.',
    pageTitle:                                             '{{applicationName}} - {{companyName}}',
    panelNameBottom:                                       'Bottom',
    panelNameLeft:                                         'Left',
    panelNameRight:                                        'Right',
    partNumber:                                            'Part number',
    partNumberShort:                                       'Part No.',
    partsList:                                             'Parts list',
    partsListExportCsv:                                    'Download CSV',
    phoneNumber:                                           'Phone number',
    placeHere:                                             'Place here',
    port:                                                  'Port',
    portCount:                                             'Ports',
    portCountButtonText:                                   'Details',
    portOverview:                                          'Port overview',
    portOverviewErrorTooLessMatrixPorts:                   'Available Matrix ports not sufficient',
    ports:                                                 'Ports',
    portsTip:                                              'Number of ports in the chassis',
    power:                                                 'Power',
    powerConsumption:                                      'Current draw',
    powerConsumptionCurrent:                               'Total',
    powerConsumptionMaximum:                               'Maximum',
    powerConsumptionRecommended:                           'Recommended',
    powerConsumptionRedundancyBoard:                       'Redundancy board',
    powerConsumptionWithDash:                              'Current draw',
    powerOverEthernet:                                     'Power over Ethernet',
    powerOverFiber:                                        'Power over Fiber',
    powerRedundancy:                                       'Power redundancy',
    print:                                                 'PDF print',
    printDialogButton:                                     'PDF print',
    printError1:                                           'An active internet connection is required to generate the PDF preview.',
    printPreview:                                          'PDF preview',
    printPreviewHTML:                                      'HTML print preview',
    productCategoryHide:                                   'Hide',
    productCategoryShow:                                   'Show',
    productImagePreview:                                   'Product image preview',
    productNoSlotsText:                                    'This product has no slots and cards that can be configured. Please refer to the specifications on the right hand side for more details.',
    productNoSlotsTitle:                                   'Information',
    productQuickSelectNoMatches:                           'No matches',
    project:                                               'Project',
    projectDescription:                                    'Project description',
    projectDescriptionTip:                                 'Here you can enter any description for your project.',
    projectFallbackName:                                   'No project name given',
    projectListSaveTip:                                    'Information on data storage',
    projectListSaveTipText:                                'All projects you create and their configurations are stored in your browser\'s "local storage" and deleted when you delete the {{applicationName}} application data from your browser.',
    projectSelection:                                      'Project selection',
    projectTitle:                                          'Project name',
    projectTitleTip:                                       'Here you can enter any title for your project. The title is also used as the file name for the export.',
    quickSearch:                                           'Quicksearch',
    quickSearchTip:                                        'Search for a product by its article number here. Selected products are automatically added to the correct position.',
    rackUnits:                                             'Rack units',
    redundant:                                             'Redundant',
    redundantNo:                                           'No',
    redundantOptional:                                     'Optional',
    redundantYes:                                          'Yes',
    reloadData:                                            'Reload all data',
    repeatSlotAmount:                                      'Slots',
    repeatSlotAmountPlaceholder:                           'Enter amount of slots...',
    repeatSlotAmountPowerConsumptionError:                 'The specified number cannot be replenished, as the total number of cards would exceed the permissible maximum power consumption.',
    repeatSlotAmountTip:                                   'Enter here how often you want to add product {{productId}} in this chassis after slot {{slotIndex}}.',
    repeatSlotText:                                        'You can repeat the current card ({{productId}}) for the following slots. Just enter the number of repetitions you want to add. There are {{freeSlots}} after your configured slot at position {{slotIndex}}.',
    repeatSlotTitle:                                       'Fill more slots',
    requestQuote:                                          'Request a quote',
    requestQuoteShort:                                     'Quote',
    resetAll:                                              'Reset everything',
    resetDialogsButtonText:                                'Reset',
    resetDialogsText:                                      'Reset dialogs',
    resetDialogsTooltip:                                   'Here you can re-display all the dialogs that you have hidden by selecting the "Do not show again" checkbox. If you click on "Reset", all dialogs will be reset.',
    resolution:                                            'Max Resolution',
    reverseLayout:                                         'Create reverse layout',
    reverseLayoutTip:                                      'With the "Reverse Layout" we automatically create the reverse configuration to the currently selected chassis configuration for you. All remote cards (R) will be replaced by local cards and all local cards by remote cards. If there is no counterpart, the slot is left free in the reverse layout. All other cards (without "L" or "R" as the first character of the part number) will remain and will be transferred to the reverse layout.',
    reversedExtenderText:                                  'The reverse layout for the extender ("{{name}}") was successfully created. Would you like to go to the reverse layout you created?',
    reversedExtenderTitle:                                 'Reverse layout created',
    reversedProductPostfix:                                '(Reverse layout)',
    rightPaneEmptyText:                                    'Please select an entry in panel on the left hand side via double click to view details here.',
    rightPaneEmptyTitle:                                   'No selection',
    salesMailboxCommentTooltip:                            'Here you can enter additional information and further comments.',
    salesMailboxCompanyError:                              'Please enter the name of the company you are working for.',
    salesMailboxCompanyTooltip:                            'Please tell us the name of the company you are working for.',
    salesMailboxEMailAddressError:                         'Please enter your e-mail address.',
    salesMailboxEMailAddressTooltip:                       'In order to be able to contact you, we would require your business e-mail address.',
    salesMailboxExtendedWarrantyText:                      'Extended warranty',
    salesMailboxFirstNameError:                            'Please enter your first name.',
    salesMailboxFirstNameTooltip:                          'Please enter your first name.',
    salesMailboxFurtherConsultationText:                   'You would like to receive further consultation on',
    salesMailboxLastNameError:                             'Please enter your last name.',
    salesMailboxLastNameTooltip:                           'Please enter your last name.',
    salesMailboxPhoneNumberError:                          'Please enter your phone number.',
    salesMailboxPhoneNumberTooltip:                        'In order to bet able to contact you, we would require your business phone number.',
    salesMailboxServiceLevelAgreementText:                 'Service Level Agreement (SLA)',
    salesMailboxSparePartsText:                            'Spare parts',
    saveNow:                                               'Export now',
    scalableline:                                          'ScalableLine',
    screenToSmallSubmit:                                   'Submit',
    screenToSmallText:                                     'The {{applicationName}} is currently only optimized for devices with a display size of 1024x768px or larger.',
    screenToSmallTitle:                                    'Your display or browser window is too small',
    selectMultipleChassisAndDeleteThem:                    'Select multiple chassis to delete them',
    selectProductCategoryText:                             'Please make a selection on the left hand side of this page',
    selection:                                             'Selection',
    serial:                                                'Serial',
    serialRs232:                                           'RS232',
    serialRs422:                                           'RS422',
    setSelectedDataConfirmButton:                          'Overwrite chassis',
    setSelectedDataConfirmText:                            'You are about to replace the current chassis ({{fromName}}) with {{fromSlots}} slots for another package ({{toName}}) with fewer slots ({{toSlots}}). All equipped slots after slot {{toSlots}} will be deleted. Would you like to continue?',
    setSelectedDataConfirmTextToImmutable:                 'You are about to replace the current chassis ({{fromName}}) with {{fromSlots}} Slots for a preconfigured and unchangeable chassis ({{toName}}). All currently configured slots will be deleted. Would you like to continue?',
    setSelectedDataConfirmTitle:                           'Overwrite chassis?',
    settings:                                              'Settings',
    sfpModules:                                            'SFP modules',
    showDetails:                                           'Show details',
    showEndOfLifeProductsFieldText:                        'Show end-of-life products',
    showEndOfLifeProductsFieldTooltipText:                 'If you activate this setting, you will still see products that are marked as end-of-life.',
    showMoreSlots:                                         'Show {{slots}} more slots',
    showOfferNumberFieldText:                              'Additional field for quotation number',
    showOfferNumberFieldTooltipText:                       'If you activate this setting, you can store an quotation number at project level.',
    showOtherCommentFieldText:                             'Additional field for other comments',
    showOtherCommentFieldTooltipText:                      'If you activate this setting, you can enter any additional comments in an additional field next to the name to each chassis and each module you inserted.',
    simultaneousDownscaling:                               'Simultaneous downscaling',
    slot:                                                  'Slot',
    slotCount:                                             'Slots',
    slotTypeAccessoryShort:                                'A',
    slotTypeCpuShort:                                      'C',
    slotTypeFanShort:                                      'F',
    slotTypePowerSupplyUnitShort:                          'P',
    slots:                                                 'Slots',
    slotsTip:                                              'Number of slots available in the chassis',
    smartDropZoneAdd:                                      'Drop here to automatically add to a useful place',
    specification:                                         'Specification',
    statusBarEmptyText:                                    'Add an extender or matrix switch to your project to see an overview over the ports here.',
    supportedVideoChannels:                                'Video channels',
    supportsPiggyback:                                     'Supports add-on modules',
    tableInputFilterNoMatches:                             'No hits',
    targetSlot:                                            'Slot',
    timeOClock:                                            'o\'clock',
    ultra:                                                 'Ultra',
    uncompressed:                                          'Uncompressed',
    unknownError:                                          'Unknown error',
    updateBrowserText:                                     'This version \'{{browserName}} {{browserVersion}}\' of your browser is currently unsupported. The {{applicationName}} works best with the latest version. Please update your browser.',
    updateBrowserTitle:                                    'Unsupported browser version detected',
    usb11:                                                 '1.1',
    usb20:                                                 '2.0',
    usb20Modules:                                          'USB 2.0 module',
    usb31:                                                 '3.1',
    usb:                                                   'USB',
    usbFlexSpeed20:                                        '2.0 Flexspeed', // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-385
    usbFlexspeed20:                                        '2.0 Flexspeed',
    usbFullSpeed20:                                        '2.0 Fullspeed ',
    usbFullspeed20:                                        '2.0 Fullspeed ', // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-385
    usbHighSpeed20:                                        '2.0 Highspeed',
    usbMemoryDeviceSupport:                                'USB memory device support',
    version:                                               'Version',
    versionNumberDateFormat:                               'DD.MM.YYYY HH:mm:ss',
    videoConnector:                                        'Video connector',
    videoConnectorTypeDisplayport:                         'DisplayPort',
    videoConnectorTypeDpHdmi:                              'DP/HDMI',
    videoConnectorTypeDualhead:                            'Dual-Head DVI-D',
    videoConnectorTypeDuallink:                            'Dual-Link DVI-D',
    videoConnectorTypeDvid:                                'DVI-D',
    videoConnectorTypeDvii:                                'DVI-I',
    videoConnectorTypeHdbnc:                               'HDBNC',
    videoConnectorTypeHdmi:                                'HDMI',
    videoConnectorTypeIp:                                  'IP',
    volt:                                                  'Volt',
    voltShort:                                             'V',
    warningErrors:                                         'Error',
    warningInformation:                                    'Tips',
    warningsInYourConfiguration:                           'Warnings in your configuration',
    wattShort:                                             'W',
    width:                                                 'Width',
    yes:                                                   'Yes',
    yourProjects:                                          'Your projects',
    zoomControlDragDropHintDetails:                        'Press ALT + Drag&Drop to duplicate modules.',
    zoomControlDragDropHintPreview:                        'Press the button on the right side to open the detail view.',
};

module.exports = english;
