//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames        from 'classnames';
import _                 from 'lodash';
import { DebounceInput } from 'react-debounce-input';

import String from '@helper/String';

import InputElement    from './InputElement';
import InputType       from './InputType';
import styles          from './styles.module.scss';
import PropTypes       from '../PropTypes';
import Tooltip         from '../Tooltip';
import TooltipPosition from '../Tooltip/TooltipPosition';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state          = {
            value: props.value,
        };
        this.inputReference = React.createRef();
        this.selection      = {
            start: false,
            end:   false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fixCursorPosition();

        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }

    handleKeyDown = (event) => {
        if (event.keyCode === 13 && this.props.onEnterKeyPress) {
            this.props.onEnterKeyPress();
        }
    };

    handleMouseDown = (event) => {
        // Prevent the mouse down event to get passed to the overlay
        // to avoid it to get accidentally closed when the user clicks (mouse down) in the input field
        // and releases the mouse key (mouse up) outside of this field.
        // Beware: Don't call event.preventDefault() here since it will make it impossible to focus the input.
        event.stopPropagation();
    };

    fixCursorPosition() {
        const current                          = this.inputReference.current;
        const { selectionStart, selectionEnd } = current;
        const selection                        = this.selection;
        const startSelectionChanged            = selection.start !== false && selection.start !== selectionStart;
        const endSelectionChanged              = selection.end !== false && selection.end !== selectionEnd;
        const overwriteCursorPosition          = startSelectionChanged || endSelectionChanged;

        if (overwriteCursorPosition) {
            current.selectionStart = selection.start;
            current.selectionEnd   = selection.end;
        }
    }

    render() {
        return (
            <div
                className={classNames(
                    styles.inputWrapper,
                    (
                        this.props.tooltip ?
                            styles.inputWrapperWithTooltip :
                            null
                    ),
                )}
            >
                {this.renderText()}
                <div className={styles.inputWrapperContent}>
                    <DebounceInput
                        autoFocus={this.props.autoFocus}
                        className={classNames(
                            styles.inputWrapperInput,
                            (
                                this.props.element === InputElement.textarea ?
                                    styles.inputWrapperInputTextarea :
                                    null
                            ),
                        )}
                        onMouseDown={this.handleMouseDown}
                        element={this.props.element}
                        onKeyDown={this.handleKeyDown}
                        max={this.props.max}
                        min={this.props.min}
                        minLength={this.props.minLength}
                        placeholder={this.props.placeholder}
                        debounceTimeout={this.props.debounceTimeout}
                        onChange={this.valueChanged}
                        type={this.props.type}
                        inputRef={this.inputReference}
                        value={this.state.value || ''}
                    />
                    {this.renderTooltip()}
                </div>
            </div>
        );
    }

    renderText = () => {
        if (this.props.text) {
            return (
                <label className={styles.inputWrapperLabel}>
                    {this.props.text}
                </label>
            );
        }

        return null;
    };

    renderTooltip = () => {
        if (this.props.tooltip) {
            return (
                <div className={styles.inputWrapperTooltip}>
                    <Tooltip
                        text={this.props.tooltip}
                        position={TooltipPosition.left}
                    />
                </div>
            );
        }

        return null;
    };

    valueChanged = (event) => {
        const originalValue                 = event.target.value;
        const valueWithoutIllegalCharacters = String.removeIllegalCharacters(originalValue);
        const input                         = this.inputReference.current;
        this.selection                      = {
            start: input.selectionStart,
            end:   input.selectionEnd,
        };

        if (originalValue !== valueWithoutIllegalCharacters) {
            event.target.value = valueWithoutIllegalCharacters;

            this.setState({
                value: valueWithoutIllegalCharacters,
            });
        }

        this.props.valueChanged(event);
    };
}

Component.propTypes = {
    autoFocus:       PropTypes.bool,
    debounceTimeout: PropTypes.number,
    element:         PropTypes.oneOf(Object.values(InputElement)),
    max:             PropTypes.number,
    min:             PropTypes.number,
    minLength:       PropTypes.number,
    onEnterKeyPress: PropTypes.func,
    placeholder:     PropTypes.string,
    text:            PropTypes.string,
    tooltip:         PropTypes.string,
    type:            PropTypes.oneOf(Object.values(InputType)),
    value:           PropTypes.string,
    valueChanged:    PropTypes.func,
};

Component.defaultProps = {
    autoFocus:       false,
    debounceTimeout: 300,
    element:         InputElement.input,
    min:             null,
    max:             null,
    minLength:       2,
    onEnterKeyPress: _.noop,
    placeholder:     '',
    text:            '',
    tooltip:         '',
    type:            InputType.text,
    value:           '',
    valueChanged:    _.noop,
};

export default Component;
