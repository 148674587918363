import { createSlice }   from '@reduxjs/toolkit';
import update            from 'immutability-helper';
import IdGenerator       from '@helper/IdGenerator';
import { ImportActions } from '@slices/import';

const initialState = {
    lastImportedProjectId:  null,
    projectDeletionContext: {
        id: null,
    },
    projects:               {},
};

const projectsSlice = createSlice({
    name:          'projects',
    initialState,
    reducers:      {
        deleteProject:           (state, action) => {
            return update(state, {
                projectDeletionContext: {
                    id: {
                        $set: action.payload.id,
                    },
                },
            });
        },
        deleteProjectCancel:     (state) => {
            return update(state, {
                projectDeletionContext: {
                    $set: initialState.projectDeletionContext,
                },
            });
        },
        deleteProjectConfirm:    (state) => {
            return update(state, {
                projects:               {
                    $unset: [
                        state.projectDeletionContext.id,
                    ],
                },
                projectDeletionContext: {
                    $set: initialState.projectDeletionContext,
                },
            });
        },
        loadProject:             (state, action) => {
            return state;
        },
        openLastImportedProject: (state, action) => {
            return state;
        },
        importIntoNewProject:    (state, action) => {
            return state;
        },
        newProject:              (state, action) => {
            return state;
        },
        saveProject:             (state, action) => {
            if (action.payload.projectData && action.payload.projectData.id) {
                return update(state, {
                    projects: {
                        [action.payload.projectData.id]: {
                            $set: {
                                ...action.payload.projectData,
                                lastUpdateDate:  new Date(),
                                routeAfterClose: null,
                            },
                        },
                    },
                });
            }

            return state;
        },
        resetAll:                (state) => {
            return update(state, {
                $set: initialState,
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ImportActions.importNewProject.type, (state, action) => {
            const importToNewProjectProjectData = {
                ...action.payload.projectData,
            };
            const importedProjectId             = `${IdGenerator.getNewProjectId()}-import`;
            importToNewProjectProjectData.id    = importedProjectId;

            return update(state, {
                lastImportedProjectId: {
                    $set: importedProjectId,
                },
                projects:              {
                    [importedProjectId]: {
                        $set: {
                            ...importToNewProjectProjectData,
                            lastUpdateDate:  new Date(),
                            routeAfterClose: null,
                        },
                    },
                },
            });
        });
    },
});

export const ProjectsActions = projectsSlice.actions;

export const ProjectsReducer = projectsSlice.reducer;

export default projectsSlice;
