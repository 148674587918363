//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// @formatter:off

import _ from 'lodash';

import AccessoryTypes from '@constants/AccessoryTypes';
import Limitations from '@constants/Limitations';
import Cast from '@helper/Cast';

import LimitationAddChassisFanIfPowerConsumptionThresholdReached from './limitationAddChassisFanIfPowerConsumptionThresholdReached';
import LimitationEndOfLifeCalculator from './limitationEndOfLife';
import LimitationLimitToCalculator from './limitationLimitTo';
import LimitationLimitToAmountCalculator from './limitationLimitToAmount';
import LimitationLimitToAmountInChassis from './limitationLimitToAmountInChassis';
import LimitationLimitToAmountOfGroupInChassisCalculator from './limitationLimitToAmountOfGroupInChassis';
import LimitationLimitToCardsInSlot from './limitationLimitToCardsInSlot';
import LimitationLimitToPsusInSlot from './limitationLimitToPsusInSlot';
import LimitationLimitToSlotInChassisCalculator from './limitationLimitToSlotInChassis';
import LimitationRecommendsModuleFanInChassis from './limitationRecommendsModuleFanInChassis';
import LimitationRequiresFanInChassisCalculator from './limitationRequiresFanInChassis';
import LimitationRequiresHostModuleCalculator from './limitationRequiresHostModule';
import LimitationShowPowerConsumptionOverloadWarning from './limitationShowPowerConsumptionOverloadWarning';
import LimitationShowPowerConsumptionThresholdWarning from './limitationShowPowerConsumptionThresholdWarning';
import LimitationShowUsbWarningCalculator from './limitationShowUsbWarning';
// @formatter:on

const limitationMap = {
    // First iteration
    //
    // Its important that "addChassisFanIfPowerConsumptionThresholdReached" is processed first
    // since it may add a fan that will make "requiresFanInChassis" obsolete
    [Limitations.addChassisFanIfPowerConsumptionThresholdReached]: LimitationAddChassisFanIfPowerConsumptionThresholdReached,

    // Second iteration
    [Limitations.endOfLife]:                            LimitationEndOfLifeCalculator,
    [Limitations.limitTo]:                              LimitationLimitToCalculator,
    [Limitations.limitToAmount]:                        LimitationLimitToAmountCalculator,
    [Limitations.limitToAmountOfGroupInChassis]:        LimitationLimitToAmountOfGroupInChassisCalculator,
    [Limitations.limitToAmountInChassis]:               LimitationLimitToAmountInChassis,
    [Limitations.limitToCardsInSlot]:                   LimitationLimitToCardsInSlot,
    [Limitations.limitToPsusInSlot]:                    LimitationLimitToPsusInSlot,
    [Limitations.limitToSlotInChassis]:                 LimitationLimitToSlotInChassisCalculator,
    [Limitations.recommendsModuleFanInChassis]:         LimitationRecommendsModuleFanInChassis,
    [Limitations.requiresFanInChassis]:                 LimitationRequiresFanInChassisCalculator,
    [Limitations.requiresHostModule]:                   LimitationRequiresHostModuleCalculator,
    [Limitations.showPowerConsumptionThresholdWarning]: LimitationShowPowerConsumptionThresholdWarning,
    [Limitations.showPowerConsumptionOverloadWarning]:  LimitationShowPowerConsumptionOverloadWarning,
    [Limitations.showUsbWarning]:                       LimitationShowUsbWarningCalculator,
};

const limitationOrder = Object.keys(limitationMap);

class LimitationsCalculator {
    static getLimitationCalculator = (limitationType) => {
        if (limitationMap[limitationType]) {
            return limitationMap[limitationType];
        }

        console.warn('Unknown limitation:', limitationType);

        return null;
    };

    static getLimitationsByPriority = (limitations) => {
        if (limitations) {
            const sortedLimitations = [...limitations].sort(this.getLimitationsByPrioritySortCallback);

            return sortedLimitations;
        }

        return [];
    };

    static getLimitationsByPrioritySortCallback = (limitation1, limitation2) => {
        const limitation1Index = limitationOrder.indexOf(limitation1.type);
        const limitation2Index = limitationOrder.indexOf(limitation2.type);

        if (limitation1Index < limitation2Index) {
            return -1;
        }

        if (limitation1Index > limitation2Index) {
            return 1;
        }

        return 0;
    };

    static slotIsValid = (
        productCategoryProduct,
        productCategorySubProduct,
        powerConsumptionInMilliamps,
        categoryType,
        slotIndex,
    ) => {
        console.log(
            'LimitationsCalculator: slotIsValid: before',
            productCategoryProduct,
            productCategorySubProduct,
        );

        const subProductCategories = Object.keys(productCategoryProduct.subProducts);
        const productWarnings      = [];

        if (productCategoryProduct.productData) {
            const sortedLimitations = this.getLimitationsByPriority(productCategoryProduct.productData.limitations);

            for (const limitation of sortedLimitations) {
                const limitationCalculator = this.getLimitationCalculator(limitation.type);

                if (limitationCalculator) {
                    if (
                        (
                            productCategorySubProduct.productData.type !== AccessoryTypes.powerSupplyUnit ||
                            limitation.type !== Limitations.showPowerConsumptionOverloadWarning
                        ) &&
                        limitation.type !== Limitations.showPowerConsumptionThresholdWarning &&
                        limitation.type !== Limitations.endOfLife
                    ) {
                        const limitationApplied = limitationCalculator.updateProductWarnings(
                            limitation,
                            null,
                            null,
                            productCategoryProduct,
                            productWarnings,
                            powerConsumptionInMilliamps,
                            categoryType,
                            null,
                        );

                        if (limitationApplied) {
                            return false;
                        }
                    }
                }
            }
        }

        for (const subProductCategoryIndex of subProductCategories) {
            const subProducts        = productCategoryProduct.subProducts[subProductCategoryIndex];
            const subProduct         = subProducts[slotIndex];
            const subProductWarnings = [];

            if (subProduct && subProduct.productData) {
                // We merge the product limitations with the slot limitations here
                // to be able to add slot errors even when the limitation is
                // applied to the chassis. This is used for
                // limitToCardsInSlot and limitToPsusInSlot.
                const allLimitations = this.getLimitationsByPriority([].concat(
                    _.get(productCategoryProduct, 'productData.limitations', []),
                    _.get(subProduct, 'productData.limitations', []),
                ));

                for (const limitation of allLimitations) {
                    const limitationCalculator = this.getLimitationCalculator(limitation.type);

                    if (limitationCalculator) {
                        if (
                            limitation.type !== Limitations.recommendsModuleFanInChassis &&
                            limitation.type !== Limitations.requiresFanInChassis &&
                            limitation.type !== Limitations.showUsbWarning
                        ) {
                            const limitationApplied = limitationCalculator.updateSubProductWarnings(
                                limitation,
                                null,
                                null,
                                productCategoryProduct,
                                subProduct,
                                Cast.int(slotIndex),
                                productWarnings,
                                subProductWarnings,
                                subProductCategoryIndex,
                                powerConsumptionInMilliamps,
                                categoryType,
                                null,
                            );

                            if (limitationApplied) {
                                console.log('LimitationsCalculator: limitationApplied: limitation', limitation);

                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    };

    static updateChangeSet = (state, changeSet, powerConsumptionInMilliamps, categoryType, productIndex) => {
        console.log('LimitationsCalculator: before', state, changeSet);

        const products               = state.products;
        const productCategoryProduct = products[categoryType][productIndex];
        const subProductCategories   = Object.keys(productCategoryProduct.subProducts);
        const productWarnings        = [];
        const appliedLimitations     = [];

        console.log(
            'limitations state productCategoryType',
            productCategoryProduct,
            categoryType,
            productIndex,
        );

        if (!changeSet.products[categoryType]) {
            changeSet.products[categoryType] = [];
        }

        if (!changeSet.products[categoryType][productIndex]) {
            changeSet.products[categoryType][productIndex] = {};
        }

        if (productCategoryProduct.productData) {
            const sortedLimitations = this.getLimitationsByPriority(productCategoryProduct.productData.limitations);

            for (const limitation of sortedLimitations) {
                const limitationCalculator = this.getLimitationCalculator(limitation.type);

                if (
                    limitationCalculator &&
                    appliedLimitations.indexOf(limitation.type) === -1
                ) {
                    const limitationApplied = limitationCalculator.updateProductWarnings(
                        limitation,
                        state,
                        changeSet,
                        productCategoryProduct,
                        productWarnings,
                        powerConsumptionInMilliamps,
                        categoryType,
                        productIndex,
                    );

                    if (limitationApplied) {
                        appliedLimitations.push(limitation.type);
                    }
                }
            }
        }

        for (const subProductCategoryIndex of subProductCategories) {
            const subProducts = productCategoryProduct.subProducts[subProductCategoryIndex];

            for (const subProductIndex in subProducts) {
                const subProduct         = subProducts[subProductIndex];
                const subProductWarnings = [];

                if (subProduct.productData) {
                    // We merge the product limitations with the slot limitations here
                    // to be able to add slot errors even when the limitation is
                    // applied to the chassis. This is used for
                    // limitToCardsInSlot and limitToPsusInSlot.
                    const allLimitations = this.getLimitationsByPriority([].concat(
                        _.get(productCategoryProduct, 'productData.limitations', []),
                        _.get(subProduct, 'productData.limitations', []),
                    ));

                    for (const limitation of allLimitations) {
                        const limitationCalculator       = this.getLimitationCalculator(limitation.type);
                        const limitationApplyKeyWithSlot = `${limitation.type}_${subProductIndex}`;

                        if (
                            limitationCalculator &&
                            (
                                appliedLimitations.indexOf(limitation.type) === -1 ||
                                // The end of life limitation can fire multiple times within a chassis.
                                // This is the case when the chassis and at least one slot has
                                // the status "eol".
                                limitation.type === Limitations.endOfLife
                            ) &&
                            appliedLimitations.indexOf(limitationApplyKeyWithSlot) === -1
                        ) {
                            const limitationApplied = limitationCalculator.updateSubProductWarnings(
                                limitation,
                                state,
                                changeSet,
                                productCategoryProduct,
                                subProduct,
                                Cast.int(subProductIndex),
                                productWarnings,
                                subProductWarnings,
                                subProductCategoryIndex,
                                powerConsumptionInMilliamps,
                                categoryType,
                                productIndex,
                            );

                            if (limitationApplied) {
                                if (
                                    limitation.type === Limitations.showUsbWarning ||
                                    limitation.type === Limitations.limitToSlotInChassis ||
                                    limitation.type === Limitations.limitTo
                                ) {
                                    appliedLimitations.push(limitation.type);
                                }

                                if (
                                    limitation.type === Limitations.limitToCardsInSlot ||
                                    limitation.type === Limitations.endOfLife
                                ) {
                                    appliedLimitations.push(limitationApplyKeyWithSlot);
                                }
                            }
                        }
                    }
                }

                if (!changeSet.products[categoryType][productIndex].subProducts) {
                    changeSet.products[categoryType][productIndex].subProducts = {};
                }

                if (!changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex]) {
                    changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex] = [];
                }

                if (!changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex][subProductIndex]) {
                    changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex][subProductIndex] = {};
                }

                console.log(
                    'limitations state subProductCategoryIndex',
                    subProductCategoryIndex,
                    subProductIndex,
                );

                // This is true when a completely new product was added (e.g. by "addChassisFanIfPowerConsumptionThresholdReached")
                if (changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex][subProductIndex].$set) {
                    changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex][subProductIndex].$set.warnings = subProductWarnings;
                } else {
                    changeSet.products[categoryType][productIndex].subProducts[subProductCategoryIndex][subProductIndex].warnings = {
                        $set: subProductWarnings,
                    };
                }
            }
        }

        changeSet.products[categoryType][productIndex].warnings = {
            $set: productWarnings,
        };

        console.log('LimitationsCalculator: after', state, changeSet);
    };
}

export default LimitationsCalculator;
