//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }   from 'connected-react-router';
import { delay }  from 'redux-saga/effects';
import { put }    from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { ActiveProjectActions }              from '@slices/activeProject';
import { ProjectsActions as ProjectActions } from '@slices/projects';
import { getPathForOverlayKey }              from '@components/OverlayManager';
import Overlays                              from '@constants/Overlays';
import Routes                                from '@constants/Routes';

const deleteProject = function* () {
    const route = getPathForOverlayKey(
        Overlays.deleteProject,
        Routes.home,
    );

    yield put(push(route));
};

const deleteProjectConfirm = function* () {
    const route = Routes.home;

    yield put(push(route));
};

const importIntoNewProject = function* () {
    const activeProject = yield select((state) => state.activeProject);

    yield put(ProjectActions.saveProject({
        projectData:     activeProject,
        routeAfterClose: getPathForOverlayKey(Overlays.import),
    }));
    yield put(ActiveProjectActions.newProject());
};

const loadProject = function* (action) {
    console.log('sagas: projects: loadProject', action);

    yield put(ActiveProjectActions.loadProject({
        projectData: action.payload.projectData,
    }));
};

const newProject = function* () {
    yield put(ActiveProjectActions.newProject());
    yield put(push(Routes.designerProjectDetails));
};

const openLastImportedProject = function* () {
    const projectsState           = yield select((state) => state.projects);
    const lastImportedProjectData = projectsState.projects[projectsState.lastImportedProjectId];

    yield put(ProjectActions.loadProject({
        projectData: lastImportedProjectData,
    }));
};

const saveProject = function* (action) {
    yield put(ActiveProjectActions.clearProject());

    const route = (
        action.payload.routeAfterClose ?
            action.payload.routeAfterClose :
            Routes.home
    );

    yield put(push(route));

    // This is a workaround for (we have to do it twice to make it work since some weird timing issues)
    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-712
    yield delay(10);
    yield put(push(route));
};

export default {
    deleteProject,
    deleteProjectConfirm,
    importIntoNewProject,
    loadProject,
    newProject,
    openLastImportedProject,
    saveProject,
};
