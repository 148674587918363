import React from 'react';

import { Provider } from 'react-redux';

import createStore from '@store/index';

import PropTypes from '../PropTypes';

const Component = ({ children }) => {
    const store = createStore();

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;
