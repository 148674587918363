//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// Types
// Actions
import { LOCATION_CHANGE } from 'connected-react-router';
import ReactTooltip        from 'react-tooltip';
import { REHYDRATE }       from 'redux-persist';
import { takeLatest }      from 'redux-saga/effects';
import { all }             from 'redux-saga/effects';

import { ActiveProjectActions } from '@slices/activeProject';
import { DebugActions }         from '@slices/debug';
import { ExportActions }        from '@slices/export';
import { ImportActions }        from '@slices/import';
import { ProductActions }       from '@slices/product';
import { ProjectsActions }      from '@slices/projects';
import { SalesMailboxActions }  from '@slices/salesMailbox';
import { SettingActions }       from '@slices/settings';

import ActiveProjectSagas from './activeProject';
import DebugSagas         from './debug';
import ExportSagas        from './export';
import ImportSagas        from './import';
import NavigationSagas    from './navigation';
import ProductSagas       from './product';
import ProjectSagas       from './projects';
import RehydrateSagas     from './rehydrate';
import SalesMailboxSagas  from './salesMailbox';
import SettingSagas       from './settings';

const root = function* () {
    yield all([
        // @formatter:off
        takeLatest(ActiveProjectActions.addProduct().type,                      ActiveProjectSagas.addProduct),
        takeLatest(ActiveProjectActions.autoFillSlots().type,                   ActiveProjectSagas.autoFillSlots),
        takeLatest(ActiveProjectActions.closeProductDetails().type,             ActiveProjectSagas.closeProductDetails),
        takeLatest(ActiveProjectActions.closeProject().type,                    ActiveProjectSagas.closeProject),
        takeLatest(ActiveProjectActions.confirmOverwriteSlot().type,            ActiveProjectSagas.confirmOverwrite),
        takeLatest(ActiveProjectActions.deleteProduct().type,                   ActiveProjectSagas.deleteProduct),
        takeLatest(ActiveProjectActions.deleteProductConfirm().type,            ActiveProjectSagas.deleteProductConfirm),
        takeLatest(ActiveProjectActions.deleteSfp().type,                       ActiveProjectSagas.deleteSfp),
        takeLatest(ActiveProjectActions.deleteSingleOrderSlot().type,           ActiveProjectSagas.deleteSingleOrderSlot),
        takeLatest(ActiveProjectActions.deleteSlot().type,                      ActiveProjectSagas.deleteSlot),
        takeLatest(ActiveProjectActions.deleteSlotConfirm().type,               ActiveProjectSagas.deleteSlotConfirm),
        takeLatest(ActiveProjectActions.deselectProduct().type,                 ActiveProjectSagas.deselectProduct),
        takeLatest(ActiveProjectActions.deselectSlot().type,                    ActiveProjectSagas.deselectSlot),
        takeLatest(ActiveProjectActions.duplicateSelectedProduct().type,        ActiveProjectSagas.duplicateSelectedProduct),
        takeLatest(ActiveProjectActions.duplicateSelectedProductConfirm().type, ActiveProjectSagas.duplicateSelectedProductConfirm),
        takeLatest(ActiveProjectActions.loadProject().type,                     ActiveProjectSagas.loadProject),
        takeLatest(ActiveProjectActions.noData().type,                          ActiveProjectSagas.noData),
        takeLatest(ActiveProjectActions.openDownloadOverlay().type,             ActiveProjectSagas.openDownloadOverlay),
        takeLatest(ActiveProjectActions.reverseLayoutedProduct().type,          ActiveProjectSagas.reverseLayoutedProduct),
        takeLatest(ActiveProjectActions.selectProduct().type,                   ActiveProjectSagas.selectProduct),
        takeLatest(ActiveProjectActions.selectSlot().type,                      ActiveProjectSagas.selectSlot),
        takeLatest(ActiveProjectActions.setActiveProductCategoryType().type,    ActiveProjectSagas.setActiveProductCategoryType),
        takeLatest(ActiveProjectActions.setProductSlot().type,                  ActiveProjectSagas.setProductSlot),
        takeLatest(ActiveProjectActions.setSelectedProductDataCheck().type,     ActiveProjectSagas.setSelectedProductDataCheck),
        takeLatest(ActiveProjectActions.downloadPdf().type,                     ActiveProjectSagas.downloadPdf),
        takeLatest(DebugActions.resetAll().type,                                DebugSagas.resetAll),
        takeLatest(ExportActions.downloadExport().type,                         ExportSagas.downloadExport),
        takeLatest(ExportActions.exportAbasSuccess().type,                      ExportSagas.exportAbasSuccess),
        takeLatest(ExportActions.exportDsdJsonSuccess().type,                   ExportSagas.exportDsdJsonSuccess),
        takeLatest(ImportActions.importToActiveProject().type,                  ImportSagas.importToActiveProject),
        takeLatest(ImportActions.importNewProject().type,                       ImportSagas.importToNewProject),
        takeLatest(LOCATION_CHANGE,                                             NavigationSagas.locationChange),
        takeLatest(ProjectsActions.deleteProject().type,                        ProjectSagas.deleteProject),
        takeLatest(ProjectsActions.deleteProjectConfirm().type,                 ProjectSagas.deleteProjectConfirm),
        takeLatest(ProjectsActions.importIntoNewProject().type,                 ProjectSagas.importIntoNewProject),
        takeLatest(ProjectsActions.loadProject().type,                          ProjectSagas.loadProject),
        takeLatest(ProjectsActions.openLastImportedProject().type,              ProjectSagas.openLastImportedProject),
        takeLatest(ProjectsActions.newProject().type,                           ProjectSagas.newProject),
        takeLatest(ProjectsActions.saveProject().type,                          ProjectSagas.saveProject),
        takeLatest(ProductActions.showIncompatibleProductOverlay().type,        ProductSagas.showIncompatibleProductOverlay),
        takeLatest(ProductActions.showEndOfLifeProductOverlay().type,           ProductSagas.showEndOfLifeProductOverlay),
        takeLatest(ProductActions.showHitLimitationOverlay().type,              ProductSagas.showHitLimitationOverlay),
        takeLatest(ImportActions.importProductUpdates().type,                   ImportSagas.importToActiveProject),
        takeLatest(REHYDRATE,                                                   RehydrateSagas.handleRehydrate),
        takeLatest(SalesMailboxActions.submit().type,                           SalesMailboxSagas.submit),
        takeLatest(SalesMailboxActions.submitSucceeded().type,                  SalesMailboxSagas.submitSuccess),
        takeLatest(SettingActions.setLanguage().type,                           SettingSagas.setLanguage),
        // @formatter:on
    ]);
};

const preLoading = function* () {
    console.log('Preloading:', 'Start');
};

const startUp = function* () {
    requestAnimationFrame(ReactTooltip.rebuild);

    yield preLoading();
};

export default {
    root,
    startUp,
};
